import React, { useEffect, useState } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ApiUtils from "../../api/ApiUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setLindkedInToken,
  linkedinUserDetails,
  logoutLinkdedinUser,
  setFacebookToken,
  logoutFacebookinUser,
  facebookProfileUrl,
} from "../../redux/slices/authSlice";
import { setActivePost } from "../../redux/slices/postActiveSlice";
import { Twitter } from "@material-ui/icons";

function LoginModal({ showModalValue, handleShow }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [code, setCode] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.authSlice);
  const { linkedInLogin } = useLinkedIn({
    clientId: "77oggcewgc7f0d",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      setCode(code);
      setErrorMessage("");
    },
    scope: "email,profile",
    onError: (error) => {
      setCode("");
      setErrorMessage(error.errorMessage);
    },
  });
  useEffect(() => {
    console.log("code",code);
    if (code && !userDetails.linkedInUserToken) {
      ApiUtils.linkedInAccessToken({ code: code }).then(async (res) => {
        localStorage.setItem(
          "social_media_automation",
          JSON.stringify({
            _linkedin_token_: res.access_token,
          })
        );
        await ApiUtils.getLinkedInUser().then((res) => {
          let formattedRes = {
            firstName: res.data.localizedFirstName,
            lastName: res.data.localizedLastName,
            displayImage: res.data.profile,
            linkedinUserId: res.data.id,
          };
          let localStorageData = JSON.parse(
            localStorage.getItem("social_media_automation")
          );
          let dataForLocal = {
            ...localStorageData,
            _linkedin_user_details_: formattedRes,
          };
          localStorage.setItem(
            "social_media_automation",
            JSON.stringify(dataForLocal)
          );
          dispatch(linkedinUserDetails(formattedRes));
          handleShow(false);
         
         
        });
        toast.success("You are successfully login in Linkedin", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(setLindkedInToken(res.access_token));
      });
    }
  }, [code]);

  const linkedInLogout = () => {
    let localStorageData = JSON.parse(
      localStorage.getItem("social_media_automation")
    );
    let dataForLocal = {
      ...localStorageData,
      _linkedin_token_: "",
      _linkedin_user_details_: "",
    };
    localStorage.setItem(
      "social_media_automation",
      JSON.stringify(dataForLocal)
    );
    dispatch(logoutLinkdedinUser());
    dispatch(setActivePost(""));
    handleShow(false);
    toast.success("You are successfully logout from Linkedin", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const facebookLogout = () => {
    let localStorageData = JSON.parse(
      localStorage.getItem("social_media_automation")
    );
    let dataForLocal = {
      ...localStorageData,
      _facebook_user_token_: "",
      _facebook_profile_picture: "",
    };
    localStorage.setItem(
      "social_media_automation",
      JSON.stringify(dataForLocal)
    );
    dispatch(logoutFacebookinUser());
    dispatch(setActivePost(""));
    handleShow(false);
    toast.success("You are successfully logout from Instagram", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const logIntoFaceBook = () => {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          let localStorageData = JSON.parse(
            localStorage.getItem("social_media_automation")
          );
          let dataForLocal = {
            ...localStorageData,
            _facebook_user_token_: response.authResponse.accessToken,
          };
          localStorage.setItem(
            "social_media_automation",
            JSON.stringify(dataForLocal)
          );
          ApiUtils.getInstagramUser().then((res) => {
            let localStorageData = JSON.parse(
              localStorage.getItem("social_media_automation")
            );
            let dataForLocal = {
              ...localStorageData,
              _facebook_profile_picture: res.data.profile_picture_url,
            };
            localStorage.setItem(
              "social_media_automation",
              JSON.stringify(dataForLocal)
            );
            dispatch(facebookProfileUrl(res.data.profile_picture_url));
          });
          handleShow(false);
          toast.success("You are successfully login in Instagram", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setFacebookToken(response.authResponse.accessToken));
        }
      },
      {
        // Scopes that allow us to publish content to Instagram
        scope:
          "instagram_basic,pages_show_list, pages_manage_posts,publish_video",
      }
    );
  };
  return (
    <Modal show={showModalValue} onHide={() => handleShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Social Accounts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup variant="flush">
          {userDetails.linkedinUserProfile ? (
            <ListGroup.Item
              className="cursor-pointer py-2"
              onClick={linkedInLogout}
            >
              <LinkedInIcon style={{ backgroundColor: "#1582BB" }} />
              <span className="fs-18">Logout from LinkedIn</span>
            </ListGroup.Item>
          ) : (
            <ListGroup.Item
              className="cursor-pointer py-2"
              onClick={linkedInLogin}
            >
              <LinkedInIcon style={{ backgroundColor: "#1582BB" }} />
              <span className="fs-18">Login into LinkedIn</span>
            </ListGroup.Item>
          )}
          {userDetails.facebookUserToken ? (
            <ListGroup.Item
              className="cursor-pointer py-3"
              onClick={facebookLogout}
            >
              <InstagramIcon style={{ backgroundColor: "#D53F69" }} />
              <span className="fs-18">Logout from Instagram</span>
            </ListGroup.Item>
          ) : (
            <ListGroup.Item
              className="cursor-pointer py-3"
              onClick={logIntoFaceBook}
            >
              <InstagramIcon style={{ backgroundColor: "#D53F69" }} />
              <span className="fs-18">Login into Instagram</span>
            </ListGroup.Item>
          )}

          <ListGroup.Item
            className="cursor-pointer py-3"
            // onClick={logIntoTwitter}
          >
            <Twitter style={{ backgroundColor: "#28a8e7" }} />
            <span className="fs-18">Login into Twitter</span>
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
