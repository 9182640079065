const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  setFacebbokPageList: "",
  getLongLivedToken: "",
};
const facebookPageSlice = createSlice({
  name: "facebook",
  initialState,
  reducers: {
    setFacebbokPageList: (state, actions) => {
      state.setFacebbokPageList = actions.payload;
    },
    getLongLivedToken: (state, actions) => {
      state.setFacebbokPageList = actions.payload;
    },
  },
});
export const { setFacebbokPageList, getLongLivedToken } =
  facebookPageSlice.actions;
export default facebookPageSlice.reducer;
