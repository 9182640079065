const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  isActiveSidebar: false,
};
const toggleSlice = createSlice({
  name: "sidebar-active",
  initialState,
  reducers: {
    setSidebarToggle: (state, actions) => {
      state.isActiveSidebar = actions.payload;
    },
  },
});

export const { setSidebarToggle } = toggleSlice.actions;
export default toggleSlice.reducer;
