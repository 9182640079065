const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  activePostTypeIs: "",
};
const postActiveSlice = createSlice({
  name: "type-post",
  initialState,
  reducers: {
    setActivePost: (state, actions) => {
      state.activePostTypeIs = actions.payload;
    },
  },
});
export const { setActivePost } = postActiveSlice.actions;
export default postActiveSlice.reducer;
