import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import postActiveSlice from "./slices/postActiveSlice";
import toggleSlice from "./slices/toggleSlice";
import facebookPageSlice from "./slices/facebookPageSlice";
const rootReducer = combineReducers({
  authSlice,
  postActiveSlice,
  toggleSlice,
  facebookPageSlice,
});
export default rootReducer;
