import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayouts from "../layouts/authLayouts";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { ToastContainer } from "react-toastify";

function Index() {
  return (
    <div>
      <Routes>
        <Route path="/linkedin" element={<LinkedInCallback />} />
        <Route path="/" element={<AuthLayouts />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default Index;
