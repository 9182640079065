import React from "react";
import CalendarMonthOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import logo from "../../assets/images/logo_sociasync.png";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { setSidebarToggle } from "../../redux/slices/toggleSlice";
function Sidebar() {
  const dispatch = useDispatch();

  const toggleValue = useSelector((state) => state.toggleSlice);
  return (
    <div
      className={
        toggleValue.isActiveSidebar
          ? "mainSidebar sm-sidebar flex-column"
          : "mainSidebar flex-column"
      }
    >
      <div className="logo d-flex justify-content-center align-items-center cursor-pointer">
        <img src={logo} width="80%" alt="logo" />
        {toggleValue.isActiveSidebar && (
          <CancelIcon
            className={toggleValue.isActiveSidebar ? " fs-20" : ""}
            onClick={() => dispatch(setSidebarToggle(false))}
          />
        )}
      </div>
      <ul className="sidebarListItems">
        <li className="itemList fs-16 d-flex align-items-center cursor-pointer">
          <span>
            <CalendarMonthOutlinedIcon />
          </span>
          Scheduled Posts
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
