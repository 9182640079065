import React, { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import img1 from "../../assets/images/image upload.png";
import noPreview from "../../assets/images/no-preview.png";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import ApiUtils from "../../api/ApiUtils";
import { useDispatch, useSelector } from "react-redux";
import { setActivePost } from "../../redux/slices/postActiveSlice";
import { UPLOAD_POST_TYPES } from "../../config/constants";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDateTime } from "../../helper/DateTimeHelper";
import moment from "moment";

function ImageUpload() {
  const [files, setFiles] = useState([]);
  const [captionText, setCaptionText] = useState("");
  const userDetails = useSelector((state) => state.authSlice);
  const currentPostName = useSelector((state) => state.postActiveSlice);
  const [socialPostTo, setSocialPostTo] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);

  const [schedulePost, setSchedulePost] = useState({
    foruploadPostImage: "forDirectPostImage",
  });
  const [selectedDate, setSelectedDate] = useState();
  const [pageList, setPageList] = useState(false);
  const [pageName, setPageName] = useState();

  const [minTime, setMinTime] = useState();

  function calculateMinTime(date) {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).add({ minutes: 5 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate();
  }

  const dispatch = useDispatch();
  const MAX_COUNT = 9;
  const onCheckSocial = (e) => {
    setSocialPostTo({ ...socialPostTo, [e.target.name]: e.target.checked });
  };

  const onCheckFaccebook = (e) => {
    if (e.target.checked) {
      setPageList(true);
      ApiUtils.getFacebbokPageList().then((res) => {
        setPageName(res.data.data);
      });
    } else {
      setPageList(false);
    }
  };

  const pageChecked = (access_token, id) => {
    const page = { access_token, id };
    const isPageSelected = selectedPages.some((page) => page.id === id);
    if (isPageSelected) {
      setSelectedPages(selectedPages.filter((page) => page.id !== id));
    } else {
      setSelectedPages([...selectedPages, page]);
    }
  };

  const handleFileSelect = (event) => {
    if (files.length === 0) {
      dispatch(setActivePost(UPLOAD_POST_TYPES.IMAGE_KEY));
    }
    if (
      event.target.files.length > MAX_COUNT ||
      files.length + event.target.files.length > MAX_COUNT
    ) {
      toast.warning("Max 9 images are allowed", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const filesArray = Array.from(event.target.files);
      const newFiles = filesArray.map((file) => {
        return {
          file: file,
          url: URL.createObjectURL(file),
        };
      });
      setFiles([...files, ...newFiles]);
    }
  };

  useEffect(() => {
    if (currentPostName.activePostTypeIs === "") {
      setCaptionText("");
      setFiles([]);
      setSocialPostTo([]);
      setSchedulePost({ foruploadPostImage: "forDirectPostImage" });
    }
  }, [currentPostName.activePostTypeIs]);
  const uploadImage = (e) => {
    e.preventDefault();
    let filesArray;

    filesArray = files.map((f) => f.file);

    const formData = new FormData();
    console.log(
      "🚀 ~ file: ImageUpload.js:117 ~ uploadImage ~ formData:",
      formData
    );
    for (var i = 0; i < filesArray.length; i++) {
      formData.append("files[]", filesArray[i]);
    }
    formData.getAll("files");

    formData.append("type", "image");
    if (socialPostTo.forFacebook) {
      formData.append("socicalType[]", ["Instagram"]);
      formData.append("instragramToken", userDetails.facebookUserToken);
      formData.append("caption", captionText);
    }
    if (socialPostTo.forLinkedin) {
      formData.append("text", captionText);
      formData.append("socicalType[]", ["LinkedIn"]);
      formData.append("linkedInToken", userDetails.linkedInUserToken);
      formData.append(
        "person_id",
        userDetails.linkedinUserProfile.linkedinUserId
      );
    }

    if (selectedPages.length > 0) {
      let pageArray;

      pageArray = selectedPages.map((f) => f);
      for (var k = 0; k < pageArray.length; k++) {
        formData.append(
          `facebookId[${k}][access_token]`,
          pageArray[k].access_token
        );
        formData.append(`facebookId[${k}][id]`, pageArray[k].id);
      }
      formData.append("text", captionText);
      formData.append("socicalType[]", ["Facebook"]);
    }

    if (socialPostTo.forTwitter) {
      formData.append("socicalType[]", ["Twittwer"]);
      formData.append("caption", captionText);
    }
    if (schedulePost?.foruploadPostImage === "forSchedulePostImage") {
      let resultedDate = convertDateTime(selectedDate);
      formData.append("upload_time", resultedDate);
    }
    uploadPost(
      schedulePost?.foruploadPostImage === "forSchedulePostImage",
      formData
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFiles("");
          setCaptionText("");
          dispatch(setActivePost(""));
          setSchedulePost({ foruploadPostImage: "forDirectPostImage" });
          setSelectedDate("");
          setMinTime("");
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
  const onChangeCheckbox = (e) => {
    setSchedulePost({ foruploadPostImage: e.target.value });
    setMinTime(
      e.target.value === "forSchedulePostImage"
        ? calculateMinTime(new Date())
        : ""
    );
    setSelectedDate("");
  };
  const uploadPost = (isSchedulePost, params) => {
    return isSchedulePost
      ? ApiUtils.schedulePostUpload(params)
      : ApiUtils.postUpload(params);
  };
  return (
    <>
      <Container className="mainContainer">
        <Row>
          <Col xl={4}>
            <div className="tab-1">
              <div className="uploadCard">
                <div className="text-center">
                  <img src={img1} width="100%" alt="" />
                  <h5 className="fs-18">Select image to share</h5>
                  <label
                    htmlFor="inputField"
                    className="btn btn-info cursor-pointer text-center"
                  >
                    <FileUploadIcon className="fs-32" />
                    Upload Image
                  </label>
                  <input
                    multiple
                    type="file"
                    id="inputField"
                    style={{ display: "none" }}
                    onChange={handleFileSelect}
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col
            xl={8}
            className="previewArea d-flex align-items-center flex-column"
          >
            {files.length > 0 ? (
              <>
                <Carousel>
                  {files.map((file, i) => {
                    return (
                      <Carousel.Item key={i}>
                        <img width="500" height="300" src={file.url} alt="" />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
                <textarea
                  placeholder="Write a post..."
                  rows="20"
                  name="comment[text]"
                  id="comment_text"
                  cols="40"
                  className="ui-autocomplete-input fs-16"
                  autoComplete="off"
                  aria-autocomplete="list"
                  aria-haspopup="true"
                  onChange={(e) => setCaptionText(e.target.value)}
                ></textarea>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {userDetails.facebookUserToken
                        ? "Post to Instagram"
                        : "Login to Instagram to Upload Post"}
                    </Tooltip>
                  }
                >
                  <span>
                    <label htmlFor="forFacebook" className="me-2 fs-16">
                      Post to Instagram
                    </label>

                    <input
                      type="checkbox"
                      id="forFacebookCheck"
                      name="forFacebook"
                      className="my-3"
                      onChange={onCheckSocial}
                      disabled={userDetails.facebookUserToken ? false : true}
                    />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {userDetails.facebookUserToken
                        ? "Post to Facebbok"
                        : "Login to Instagram to Upload Post"}
                    </Tooltip>
                  }
                >
                  <span>
                    <label htmlFor="forFacebookPage" className="me-2 fs-16">
                      Post to Facebook
                    </label>

                    <input
                      type="checkbox"
                      id="forFacebookCheck"
                      name="forFacebookPage"
                      className="my-3"
                      onChange={onCheckFaccebook}
                      disabled={userDetails.facebookUserToken ? false : true}
                    />
                  </span>
                </OverlayTrigger>

                <div>
                  {pageList ? (
                    <div className="card" style={{ width: "300px" }}>
                      <div className="card-body">
                        <h5 className="card-title  text-center">Select Page</h5>
                        {pageName?.map((data) => (
                          <span
                            key={data.name}
                            className="d-flex justify-content-between"
                          >
                            <label
                              htmlFor="forFacebookPage"
                              className="me-2 fs-16"
                            >
                              {data.name}
                            </label>

                            <input
                              type="checkbox"
                              id="forFacebookCheck"
                              name="forFacebookPage"
                              className="my-2"
                              onChange={() =>
                                pageChecked(data.access_token, data.id)
                              }
                            />
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {userDetails.linkedInUserToken
                        ? "Post to LinkedIn"
                        : "Login to LinkedIn to Upload Post"}
                    </Tooltip>
                  }
                >
                  <span>
                    <label htmlFor="forLinkedin" className="me-2 fs-16">
                      Post to LinkedIn
                    </label>

                    <input
                      type="checkbox"
                      id="forLinkedinCheck"
                      name="forLinkedin"
                      className="my-3"
                      onChange={onCheckSocial}
                      disabled={userDetails.linkedInUserToken ? false : true}
                    />
                  </span>
                </OverlayTrigger>

                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">Post to Twitter</Tooltip>
                  }
                >
                  <span>
                    <label htmlFor="forTwitter" className="me-2 fs-16">
                      Post to Twitter
                    </label>

                    <input
                      type="checkbox"
                      id="forFacebookCheck"
                      name="forTwitter"
                      className="my-3"
                      onChange={onCheckSocial}
                    />
                  </span>
                </OverlayTrigger>

                <span>
                  <label htmlFor="forSchedulePostImage" className="me-2 fs-16">
                    Direct Post
                  </label>
                  <input
                    type="radio"
                    value="forDirectPostImage"
                    name="foruploadPostImage"
                    checked={
                      schedulePost?.foruploadPostImage === "forDirectPostImage"
                    }
                    onChange={onChangeCheckbox}
                  />
                </span>
                <span>
                  <label htmlFor="forSchedulePostImage" className="me-2 fs-16">
                    Schedule Post
                  </label>
                  <input
                    type="radio"
                    value="forSchedulePostImage"
                    name="foruploadPostImage"
                    className="my-3"
                    onChange={onChangeCheckbox}
                    checked={
                      schedulePost?.foruploadPostImage ===
                      "forSchedulePostImage"
                    }
                  />
                </span>
                {schedulePost?.foruploadPostImage ===
                  "forSchedulePostImage" && (
                  <div className="justify-content-center">
                    <DatePicker
                      selected={selectedDate}
                      minDate={new Date()}
                      minTime={minTime}
                      maxTime={moment().endOf("day").toDate()}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setMinTime(calculateMinTime(date));
                      }}
                      showTimeInput
                      timeFormat="p"
                      timeIntervals={1}
                      dateFormat="Pp"
                      className="input-text mb-3 fs-16"
                      placeholderText="Please select date & time"
                    />
                  </div>
                )}
                <button
                  className="btn btn-info cursor-pointer"
                  onClick={uploadImage}
                  disabled={
                    socialPostTo.forFacebook ||
                    socialPostTo.forLinkedin ||
                    selectedPages.length > 0 ||
                    socialPostTo.forTwitter
                      ? false
                      : true
                  }
                >
                  Post
                </button>
              </>
            ) : (
              <img src={noPreview} width="40%" alt="" />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ImageUpload;
