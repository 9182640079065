import React from "react";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import Main from "../components/Main/Main";
import "../App.scss";

function AuthLayouts() {
  return (
    <div>
      <Sidebar />
      <div className="mainLayout">
        <Header />
        <Main />
      </div>
    </div>
  );
}

export default AuthLayouts;
