import React, { useEffect, useState } from "react";
import { Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import img1 from "../../assets/images/article.png";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ApiUtils from "../../api/ApiUtils";
import { useDispatch, useSelector } from "react-redux";
import { setActivePost } from "../../redux/slices/postActiveSlice";
import { UPLOAD_POST_TYPES } from "../../config/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDateTime } from "../../helper/DateTimeHelper";
import moment from "moment";

function ArticleUpload() {
  const [articleText, setArticleValue] = useState("");
  const [schedulePost, setSchedulePost] = useState({
    foruploadPostArticle: "forDirectPostArticle",
  });
  const [selectedDate, setSelectedDate] = useState();
  const [socialPostTo, setSocialPostTo] = useState([]);
  const userDetails = useSelector((state) => state.authSlice);
  const [minTime, setMinTime] = useState();
  const [pageList, setPageList] = useState(false);
  const [pageName, setPageName] = useState();
  const [selectedPages, setSelectedPages] = useState([]);
  function calculateMinTime(date) {
    let isToday = moment(date).isSame(moment(), "day");
    if (isToday) {
      let nowAddOneHour = moment(new Date()).add({ minutes: 5 }).toDate();
      return nowAddOneHour;
    }
    return moment().startOf("day").toDate();
  }
  const onCheckFaccebook = (e) => {
    if (e.target.checked) {
      setPageList(true);
      ApiUtils.getFacebbokPageList().then((res) => {
        setPageName(res.data.data);
      });
    } else {
      setPageList(false);
    }
  };

  const currentPostName = useSelector((state) => state.postActiveSlice);
  const dispatch = useDispatch();
  const uploadArticle = () => {
    if (socialPostTo.forLinkedin) {
      const formData = new FormData();
      formData.append(
        "person_id",
        userDetails.linkedinUserProfile.linkedinUserId
      );
      const socicalType = ["LinkedIn"];
      formData.append("text", articleText);
      formData.append("type", "text");
      formData.append("socicalType[]", socicalType);
      formData.append("linkedInToken", userDetails.linkedInUserToken);

      if (schedulePost?.foruploadPostArticle === "forSchedulePostArticle") {
        let resultedDate = convertDateTime(selectedDate);
        formData.append("upload_time", resultedDate);
      }
      uploadPost(
        schedulePost?.foruploadPostArticle === "forSchedulePostArticle",
        formData
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setArticleValue("");
            dispatch(setActivePost(""));
            setSchedulePost({ foruploadPostArticle: "forDirectPostArticle" });
            setSelectedDate("");
            setMinTime("");
            setPageList(false);
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
    if (socialPostTo.forTwitter) {
      const formData = new FormData();
      const socicalType = ["Twitter"];
      formData.append("text", articleText);
      formData.append("type", "text");
      formData.append("socicalType[]", socicalType);

      if (schedulePost?.foruploadPostArticle === "forSchedulePostArticle") {
        let resultedDate = convertDateTime(selectedDate);
        formData.append("upload_time", resultedDate);
      }
      uploadPost(
        schedulePost?.foruploadPostArticle === "forSchedulePostArticle",
        formData
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setArticleValue("");
            dispatch(setActivePost(""));
            setSchedulePost({ foruploadPostArticle: "forDirectPostArticle" });
            setSelectedDate("");
            setMinTime("");
            setPageList(false);
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }


    const checkboxElement = document.getElementById("forFacebookCheck");

    if (selectedPages.length > 0) {
      const formData = new FormData();
      let filesArray;
      filesArray = selectedPages.map((f) => f);
      for (var i = 0; i < filesArray.length; i++) {
        formData.append(
          `facebookId[${i}][access_token]`,
          filesArray[i].access_token
        );
        formData.append(`facebookId[${i}][id]`, filesArray[i].id);
      }
      formData.getAll("facebookId[]");
      const socicalType = ["Facebook"];
      formData.append("type", "text");
      formData.append("text", articleText);
      formData.append("socicalType[]", socicalType);
      // formData.append("facebookId[]", selectedPages);

      if (schedulePost?.foruploadPostArticle === "forSchedulePostArticle") {
        let resultedDate = convertDateTime(selectedDate);
        formData.append("upload_time", resultedDate);
      }
      uploadPost(
        schedulePost?.foruploadPostArticle === "forSchedulePostArticle",
        formData
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(res.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setArticleValue("");
            dispatch(setActivePost(""));
            setSchedulePost({ foruploadPostArticle: "forDirectPostArticle" });
            setSelectedDate("");
            setMinTime("");
            setPageList(false);
            checkboxElement.checked = false;
            selectedPages.length = 0;
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      toast.warning("Please fill the input to post anything", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const onCheckSocial = (e) => {
    setSocialPostTo({ ...socialPostTo, [e.target.name]: e.target.checked });
  };

  const uploadPost = (isSchedulePost, params) => {
    return isSchedulePost
      ? ApiUtils.schedulePostUpload(params)
      : ApiUtils.postUpload(params);
  };
  const handleInputChange = (e) => {
    if (articleText.length === 0) {
      dispatch(setActivePost(UPLOAD_POST_TYPES.ARTICLE_KEY));
    }
    setArticleValue(e.target.value);
  };
  useEffect(() => {
    if (currentPostName.activePostTypeIs === "") {
      setArticleValue("");
      setSchedulePost({ foruploadPostArticle: "forDirectPostArticle" });
    }
  }, [currentPostName.activePostTypeIs]);

  const onChangeCheckbox = (e) => {
    setSchedulePost({ foruploadPostArticle: e.target.value });
    setMinTime(
      e.target.value === "forSchedulePostArticle"
        ? calculateMinTime(new Date())
        : ""
    );
    setSelectedDate("");
  };

  const pageChecked = (access_token, id) => {
    const page = { access_token, id };
    const isPageSelected = selectedPages.some((page) => page.id === id);
    if (isPageSelected) {
      setSelectedPages(selectedPages.filter((page) => page.id !== id));
    } else {
      setSelectedPages([...selectedPages, page]);
    }
  };

  return (
    <>
      <Container className="mainContainer">
        <Row>
          <Col xl={4}>
            <div className="tab-1">
              <div className="uploadCard">
                <div className="text-center">
                  <img src={img1} width="100%" alt="" />

                  <h5 className="fs-18">Write article to post</h5>
                  <label
                    className="btn btn-info text-center"
                    style={{ pointerEvents: "none" }}
                  >
                    <EditNoteIcon className="fs-32" />
                    Write Article
                  </label>
                </div>
              </div>
            </div>
          </Col>

          <Col
            xl={8}
            className="previewArea d-flex align-items-center flex-column"
          >
            <textarea
              placeholder="Write a post..."
              rows="20"
              name="comment[text]"
              id="comment_text"
              cols="40"
              className="ui-autocomplete-input fs-16"
              autoComplete="off"
              aria-autocomplete="list"
              aria-haspopup="true"
              value={articleText}
              required
              onChange={handleInputChange}
            ></textarea>

            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  {userDetails.linkedInUserToken
                    ? "Post to LinkedIn"
                    : "Login to LinkedIn to Upload Post"}
                </Tooltip>
              }
            >
              <span>
                <label htmlFor="forLinkedin" className="me-2 fs-16">
                  Post to LinkedIn
                </label>

                <input
                  type="checkbox"
                  id="forLinkedinCheck"
                  name="forLinkedin"
                  className="my-3"
                  onChange={onCheckSocial}
                  disabled={userDetails.linkedInUserToken ? false : true}
                />
              </span>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  {userDetails.facebookUserToken
                    ? "Post to Facebbok"
                    : "Login to Instagram to Upload Post"}
                </Tooltip>
              }
            >
              <span>
                <label htmlFor="forFacebookPage" className="me-2 fs-16">
                  Post to Facebook
                </label>

                <input
                  type="checkbox"
                  id="forFacebookCheck"
                  name="forFacebookPage"
                  className="my-3"
                  onChange={onCheckFaccebook}
                  disabled={userDetails.facebookUserToken ? false : true}
                />
              </span>
            </OverlayTrigger>

            <div>
              {pageList ? (
                <div className="card" style={{ width: "300px" }}>
                  <div className="card-body">
                    <h5 className="card-title  text-center">Select Page</h5>
                    {pageName?.map((data) => (
                      <span
                        key={data.name}
                        className="d-flex justify-content-between"
                      >
                        <label htmlFor="forFacebookPage" className="me-2 fs-16">
                          {data.name}
                        </label>

                        <input
                          type="checkbox"
                          id="forFacebookCheck"
                          name="forFacebookPage"
                          className="my-2"
                          onChange={() =>
                            pageChecked(data.access_token, data.id)
                          }
                        />
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>

            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">Post to Twitter</Tooltip>}
            >
              <span>
                <label htmlFor="forTwitter" className="me-2 fs-16">
                  Post to Twitter
                </label>

                <input
                  type="checkbox"
                  id="forFacebookCheck"
                  name="forTwitter"
                  className="my-3"
                  onChange={onCheckSocial}
                />
              </span>
            </OverlayTrigger>

            <span>
              <label htmlFor="forSchedulePostArticle" className="me-2 fs-16">
                Direct Post
              </label>
              <input
                type="radio"
                value="forDirectPostArticle"
                name="foruploadPostArticle"
                checked={
                  schedulePost?.foruploadPostArticle === "forDirectPostArticle"
                }
                onChange={onChangeCheckbox}
              />
            </span>
            <span>
              <label htmlFor="forSchedulePostArticle" className="me-2 fs-16">
                Schedule Post
              </label>
              <input
                type="radio"
                value="forSchedulePostArticle"
                name="foruploadPostArticle"
                className="my-3"
                onChange={onChangeCheckbox}
                checked={
                  schedulePost?.foruploadPostArticle ===
                  "forSchedulePostArticle"
                }
              />
            </span>
            {schedulePost?.foruploadPostArticle ===
              "forSchedulePostArticle" && (
              <div className="justify-content-center">
                <DatePicker
                  selected={selectedDate}
                  minDate={new Date()}
                  minTime={minTime}
                  maxTime={moment().endOf("day").toDate()}
                  onChange={(date) => {
                    setSelectedDate(date);
                    setMinTime(calculateMinTime(date));
                  }}
                  timeFormat="p"
                  timeIntervals={1}
                  dateFormat="Pp"
                  className="input-text mb-3 fs-16"
                  // showTimeInput
                  showTimeSelect
                  placeholderText="Please select date & time"
                />
              </div>
            )}
            <button
              className="btn btn-info cursor-pointer"
              onClick={uploadArticle}
              disabled={
                socialPostTo.forLinkedin ||
                selectedPages.length > 0 ||
                socialPostTo.forTwitter
                  ? false
                  : true
              }
            >
              Post
            </button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ArticleUpload;
