const BASE_URL = process.env.REACT_APP_BASE_URL;
const UPLOAD_POST_TYPES = {
  ARTICLE_KEY: "article",
  IMAGE_KEY: "image",
  VIDEO_KEY: "video",
};

module.exports = {
  BASE_URL,
  UPLOAD_POST_TYPES,
};
