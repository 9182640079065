import api from "./Index";

const ApiUtils = {
  // linkedin access token
  linkedInAccessToken: async function (params) {
    try {
      const response = await api.post("linkedin-access-token", params);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  //get user details of linkedin
  getLinkedInUser: async function () {
    try {
      const response = await api.get("get-user-detail");
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
  //get instagram profile
  getInstagramUser: async function () {
    try {
      const response = await api.get("get-instagram-profile");
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
  //image, text, video upload
  postUpload: async function (params) {
    try {
      const response = await api.post("image-share", params);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
  //schedule post for image, text, video upload
  schedulePostUpload: async function (params) {
    try {
      const response = await api.post("upload_schedule_post", params);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
  //get facebook pagelist
  getFacebbokPageList: async function () {
    try {
      const response = await api.get("get-page-list-with-token");
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
  //get facebook page long lived token
  getLongLivedToken: async function (data) {
    try {
      const response = await api.post("get-long-lived-access-token", data);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
};

export default ApiUtils;
