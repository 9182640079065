import axios from "axios";
import { BASE_URL } from "../config/constants";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const localStorageValue = JSON.parse(
      localStorage.getItem("social_media_automation")
    );
    const loader = document.querySelector("#preloader");
    loader.style.display = "block";

    if (localStorageValue?._linkedin_token_) {
      config.headers[
        "Authorization"
      ] = `Bearer ${localStorageValue?._linkedin_token_}`;
    }
    if (localStorageValue?._facebook_user_token_) {
      config.headers[
        "Authorization"
      ] = `Bearer ${localStorageValue?._facebook_user_token_}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const loader = document.querySelector("#preloader");
    loader.style.display = "none";
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const loader = document.querySelector("#preloader");
    loader.style.display = "none";
    return Promise.reject(error);
  }
);
export default instance;
