const { createSlice } = require("@reduxjs/toolkit");

const localStorageValue = JSON.parse(
  localStorage.getItem("social_media_automation")
);
const initialState = {
  linkedInUserToken: localStorageValue?._linkedin_token_,
  linkedinUserProfile: localStorageValue?._linkedin_user_details_,
  facebookUserToken: localStorageValue?._facebook_user_token_,
  facebookProfileUrl: localStorageValue?._facebook_profile_picture,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLindkedInToken: (state, action) => {
      state.linkedInUserToken = action.payload;
    },
    linkedinUserDetails: (state, action) => {
      state.linkedinUserProfile = action.payload;
    },
    facebookProfileUrl: (state, action) => {
      state.facebookProfileUrl = action.payload;
    },
    logoutLinkdedinUser: (state) => {
      state.linkedInUserToken = "";
      state.linkedinUserProfile = "";
    },
    setFacebookToken: (state, action) => {
      state.facebookUserToken = action.payload;
    },
    logoutFacebookinUser: (state) => {
      state.facebookUserToken = "";
      state.facebookProfileUrl = "";
    },
  },
});

export const {
  setLindkedInToken,
  linkedinUserDetails,
  logoutLinkdedinUser,
  setFacebookToken,
  logoutFacebookinUser,
  facebookProfileUrl,
} = authSlice.actions;
export default authSlice.reducer;
