import React, { useState } from "react";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import ArticleIcon from "@mui/icons-material/Article";
import ImageUpload from "../Uploads/ImageUpload";
import VideoUpload from "../Uploads/VideoUpload";
import { Card, Col, Nav, Row, Tab } from "react-bootstrap";
import ArticleUpload from "../Uploads/ArticleUpload";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_POST_TYPES } from "../../config/constants";
import { setActivePost } from "../../redux/slices/postActiveSlice";
import LoginModal from "../Common/LoginModal";

function Main() {
  const [activeKey, setActiveKey] = useState("article");
  const userDetails = useSelector((state) => state.authSlice);
  const postTypeIs = useSelector((state) => state.postActiveSlice);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const checkPosTypeForImage = (key) => {
    if (
      (key !== UPLOAD_POST_TYPES.ARTICLE_KEY &&
        postTypeIs.activePostTypeIs === UPLOAD_POST_TYPES.ARTICLE_KEY) ||
      (key !== UPLOAD_POST_TYPES.VIDEO_KEY &&
        postTypeIs.activePostTypeIs === UPLOAD_POST_TYPES.VIDEO_KEY) ||
      (key !== UPLOAD_POST_TYPES.IMAGE_KEY &&
        postTypeIs.activePostTypeIs === UPLOAD_POST_TYPES.IMAGE_KEY)
    ) {
      if (
        window.confirm("Changes will be discard. Are you sure to continue.")
      ) {
        setActiveKey(key);
        dispatch(setActivePost(""));
      } else {
      }
    } else {
      setActiveKey(key);
    }
  };
  return (
    <>
      <div className="contentWrapper d-flex justify-content-center align-items-center flex-column p-md-5 p-2 ">
        {!userDetails.linkedInUserToken && !userDetails.facebookUserToken ? (
          <>
            <h2>
              Tecmantras is the easiest way to manage all your Social Accounts
              from one place
            </h2>
            <img
              src="https://postcron.com/app/assets/icons/no-profiles-hero-img.svg"
              alt=""
            />
            <button
              className="startButton fs-20 d-block cursor-pointer"
              onClick={handleShow}
            >
              Start
            </button>
          </>
        ) : (
          <Card>
            <Card.Body className="d-flex flex-column">
              <div className="composer d-md-flex">
                <div className="avatar mt-2 mb-3 mx-auto">
                  <span className="d-flex">
                    {!userDetails.linkedInUserToken ? (
                      ""
                    ) : (
                      <img
                        src={
                          userDetails?.linkedinUserProfile?.displayImage === ""
                            ? "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                            : userDetails?.linkedinUserProfile?.displayImage
                        }
                        alt=""
                        className="linkedin-profile-img"
                      />
                    )}
                    {!userDetails.facebookUserToken ? (
                      ""
                    ) : (
                      <img
                        src={
                          userDetails?.facebookProfileUrl === ""
                            ? "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                            : userDetails?.facebookProfileUrl
                        }
                        alt=""
                        className="instagram-profile-img"
                      />
                    )}
                  </span>
                </div>
                <div className="shareBox">
                  <Tab.Container
                    id="left-tabs-example"
                    activeKey={activeKey}
                    onSelect={(key) => checkPosTypeForImage(key)}
                  >
                    <Row>
                      <Col sm={12}>
                        <Nav
                          variant="pills"
                          className="flex-row d-flex align-items-center"
                        >
                          <Nav.Item className="d-flex align-items-center cursor-pointer">
                            <Nav.Link
                              eventKey={UPLOAD_POST_TYPES.ARTICLE_KEY}
                              className="picBtn"
                            >
                              <ArticleIcon className="fs-29" />
                              <span className="fs-16">Article</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="d-flex align-items-center cursor-pointer">
                            <Nav.Link
                              eventKey={UPLOAD_POST_TYPES.IMAGE_KEY}
                              className="picBtn"
                            >
                              <InsertPhotoIcon className="fs-29" />
                              <span className="fs-16">Photo</span>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="d-flex align-items-center cursor-pointer">
                            <Nav.Link
                              eventKey={UPLOAD_POST_TYPES.VIDEO_KEY}
                              className="picBtn"
                            >
                              <SmartDisplayIcon className="fs-29" />
                              <span className="fs-16">Video</span>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey={UPLOAD_POST_TYPES.ARTICLE_KEY}>
                            <ArticleUpload />
                          </Tab.Pane>
                          <Tab.Pane eventKey={UPLOAD_POST_TYPES.IMAGE_KEY}>
                            <ImageUpload />
                          </Tab.Pane>
                          <Tab.Pane eventKey={UPLOAD_POST_TYPES.VIDEO_KEY}>
                            <VideoUpload />
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
      {show && (
        <LoginModal
          showModalValue={show}
          handleShow={(active) => setShow(active)}
        />
      )}
    </>
  );
}

export default Main;
