import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import LoginModal from "../Common/LoginModal";
import { setSidebarToggle } from "../../redux/slices/toggleSlice";
function Header() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const userDetails = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  // const toggleValue = useSelector((state) => state.toggleSlice);

  return (
    <>
      <div className="mainHeader fs-20 d-flex justify-content-between align-items-center">
        <div
          className="toggleIcon cursor-pointer"
          onClick={() => dispatch(setSidebarToggle(true))}
        >
          <MenuIcon />
        </div>

        <p className="headerTitle">Scheduled Posts</p>
        <div className="avatar">
          {!userDetails.linkedInUserToken ? (
            ""
          ) : (
            <span className="avatar-badge linkedin-profile-img">
              <img
                src={
                  userDetails?.linkedinUserProfile?.displayImage === ""
                    ? "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                    : userDetails?.linkedinUserProfile?.displayImage
                }
                alt="linkedinUserProfile"
              />
              <LinkedInIcon />
            </span>
          )}
          {!userDetails.facebookUserToken ? (
            ""
          ) : (
            <span className="avatar-badge instagram-profile-img">
              <img
                src={
                  userDetails?.facebookProfileUrl === ""
                    ? "https://cdn-icons-png.flaticon.com/512/219/219983.png"
                    : userDetails?.facebookProfileUrl
                }
                alt="facebookProfilePhoto"
              />
              <InstagramIcon />
            </span>
          )}
          <span className="cursor-pointer fs-30" onClick={handleShow}>
            +
          </span>
        </div>
      </div>
      {show && (
        <LoginModal
          showModalValue={show}
          handleShow={(active) => setShow(active)}
        />
      )}
    </>
  );
}

export default Header;
